import { AxiosRequestConfig } from 'axios';
import { SortDirections } from '@faxi/web-component-library';

import httpClient from '../httpClient';
import {
  APIResponse,
  Depot,
  RewardType,
  DepotState,
  PaginatedDataSimple,
  PaginatedResponse,
  EarnedReward,
  LeaderBoardFilterType,
} from 'models';
import { getFormData } from 'utils';

const createDepot = async (data: Partial<Depot>) =>
  httpClient.post<APIResponse<Depot>>('depots', data);

const updateDepot = async (depotId: string, data: Partial<Depot>) =>
  httpClient.post<Depot>(`depots/${depotId}`, data, {
    params: { _method: 'PUT' },
  });

const deleteDepot = (depotId: string) =>
  httpClient.post(
    `depots/${depotId}`,
    {},
    {
      params: { _method: 'DELETE' },
    }
  );

const getDepots = async () =>
  httpClient.get<APIResponse<{ depots: Depot[]; total: number }>>(`depots`);

const getDepotById = async (depotId: string) =>
  httpClient.get<APIResponse<Depot>>(`depots/${depotId}`);

const getOrganisationDepots = async (
  oid: number,
  type: 'gamification' | 'point',
  state?: DepotState
) =>
  httpClient.get<APIResponse<{ depots: Depot[]; total: number }>>(
    `depots/organisation/${oid}`,
    {
      params: {
        type,
        state,
      },
    }
  );

const createReward = async (communityId: number, reward: RewardType) => {
  const formData = getFormData(reward);
  formData.append('community_id', `${communityId}`);

  return httpClient.post<APIResponse<RewardType>>(`rewards`, formData);
};

const updateReward = (rid: string, reward: RewardType) => {
  const formData = getFormData(reward);

  return httpClient.put<APIResponse<RewardType>>(
    `rewards/${rid}`,
    formData,
    {}
  );
};

const deleteReward = (rid: string) => httpClient.delete(`rewards/${rid}`, {});

const getRewardById = async (
  rid: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient.get<APIResponse<RewardType>>(`rewards/${rid}`, {
    ...config,
  });

const getRewardsPaginated = async (
  community_id: string,
  params: {
    count?: number;
    offset?: string;
    sort?: Partial<keyof RewardType>;
    direction?: SortDirections;
    search?: string;
  },
  config?: Partial<AxiosRequestConfig>
): Promise<PaginatedDataSimple<RewardType, 'rewards'>> =>
  httpClient
    .get(`rewards`, {
      params: { ...params, community_id },
      ...config,
    })
    .then((res) => res.data);

const getEarnedRewards = async (args: {
  oid: string;
  count: number;
  offset: number;
  search: string;
  sort_by?: Partial<keyof EarnedReward>;
  sort_direction?: SortDirections;
  start_date?: string;
  end_date?: string;
  config?: Partial<AxiosRequestConfig>;
}) => {
  const { config, ...restArgs } = args;
  return httpClient
    .get<PaginatedResponse<EarnedReward, 'rewards'>>(`admin/rewards`, {
      params: {
        ...restArgs,
      },
      ...config,
    })
    .then((res) => res.data);
};

const deleteRewardImage = (rid: string) =>
  httpClient.post<APIResponse<RewardType>>(
    `rewards/${rid}/image`,
    {},
    {
      params: {
        _method: 'DELETE',
      },
    }
  );

const getLeaderBoardsPaginated = async (
  community_id: string,
  params: {
    count?: number;
    offset?: string;
    search?: string;
  },
  config?: Partial<AxiosRequestConfig>
): Promise<PaginatedDataSimple<LeaderBoardFilterType, 'rewards'>> =>
  httpClient
    .get(`rewards`, {
      params: { ...params, community_id },
      ...config,
    })
    .then((res) => res.data);

const hideEarnedReward = (
  depotInstanceRewardId: number,
  organisation_id: string
) =>
  httpClient.post(
    `depot-instances/reward/${depotInstanceRewardId}/hide`,
    {},
    {
      params: { organisation_id },
    }
  );

const deactivateReward = (
  depotInstanceRewardID: number,
  organisation_id: string,
  cancel_why: string
) => {
  const formData = getFormData({ cancel_why });

  return httpClient.post(
    `depot-instances/reward/${depotInstanceRewardID}/cancel`,
    formData,
    {
      params: { organisation_id },
    }
  );
};

export default {
  createDepot,
  updateDepot,
  deleteDepot,
  getDepots,
  getDepotById,
  getOrganisationDepots,
  createReward,
  updateReward,
  deleteReward,
  deleteRewardImage,
  getRewardsPaginated,
  getEarnedRewards,
  getRewardById,
  getLeaderBoardsPaginated,
  hideEarnedReward,
  deactivateReward,
};
